<template>
    <div class="d-lg-none py-4">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-2">
                <div class="mb-5">
                    <div class="gr-3-cols">

                        <div class="fl-gr-1 p-2 b-1">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r">
                                    <!--                                    <div class="line-right-1"></div>-->
                                    <span class="font-inter-semi-bold fs--1">Order</span>
                                </div>
                            </div>
                        </div>

                        <div class="fl-gr-1 p-2 b-1">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r">
                                    <!--                                    <div class="line-right-1"></div>-->
                                    <span class="font-inter-semi-bold fs--1">Title</span>
                                </div>
                            </div>
                        </div>
                        <div class="fl-gr-1 p-2 b-1">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r">
                                    <!--                                    <div class="line-right-1"></div>-->
                                    <span class="font-inter-semi-bold fs--1">Enabled</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="bs-3">
                        <div class="gr-3-cols font-inter-medium">

                            <div class=" p-2 b-1">
                                <div class="fl-x fl-j-c fl-a-c h-100">
                                    <div class="pos-r fl-x-cc h-100">
                                        <!--                                        <div class="line-right-1"></div>-->
                                        {{ i.order }}
                                    </div>
                                </div>
                            </div>
                            <div class=" p-2 b-1">
                                <div class="fl-x fl-j-c fl-a-c h-100">
                                    <div class="pos-r fl-x-cc h-100">
                                        <!--                                        <div class="line-right-1"></div>-->
                                        {{ i.title }}
                                    </div>
                                </div>
                            </div>
                            <div class=" p-2 b-1">
                                <div class="fl-x fl-j-c fl-a-c h-100">
                                    <div class="pos-r fl-x-cc h-100">
                                        <span class="ml-2" v-if="i.is_enabled">Yes</span>
                                        <span class="ml-2" v-else>No</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row b-1 fs--1 m-0">
                            <span class="b-1 w-100 col-12 p-2">
                                <div class="p-1 fl-x-cc">
                                    <div class="btn-group">
                                        <edit-btn class="px-3 px-lg-0" icon="fa fa-pencil" text="Edit"
                                                  @click="$emit('setEdit', i)"/>
                                        <delete-btn class="px-3 px-lg-0" @click="$emit('setDelete', i)"/>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../../../data/cms-urls';
import _ from 'lodash';

export default {
    name : 'HowItWorksMobileView',

    props : {
        ext_search : {
            type    : String,
            default : ''
        }
    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '5',
            loading  : true,
            error    : false,
            details  : null,
            listUrl  : urls.cms.howItWorks.list
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    watch : {
        ext_search : _.debounce(function (newVal) {
            this.loadData();
        }, 2000)
    },

    mounted () {
        this.loadData();
    },

    methods : {
        async loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            await axios.get(this.listUrl, {
                params : {
                    page       : that.page,
                    per_page   : that.per_page,
                    ext_search : that.ext_search
                }
            }).then(function (response) {
                console.log('response-client', response);
                const json = response.data;
                that.details = { ...json };
                that.setPagination();
                that.loading = false;
                that.error = false;
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        }

    }
};
</script>

<style scoped>

</style>
