<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>HOW IT WORKS</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.howItWorksAddModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                          class="d-sm-none-lg-block" :extra-params="{ext_search}">
            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>
        <how-it-works-mobile-view :ext_search="ext_search" @setEdit="setEdit" @setDelete="setDelete"
                                  class="d-lg-none" ref="howItWorkMobileView"></how-it-works-mobile-view>
        <modal title="Add How it Works" ref="howItWorksAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddHowItWorks @success="formSuccess"></AddHowItWorks>
        </modal>

        <modal title="Edit How it Works" ref="howItWorksEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditHowItWorks :initial-data="editingItem" @success="formSuccess"></EditHowItWorks>
        </modal>

        <delete-modal ref="howItWorksDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the How it Works <b v-html="deletingItem && deletingItem.title"></b>. Are you
                sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.title"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddHowItWorks from './AddHowItWorks';
import EditHowItWorks from './EditHowItWorks';
import HowItWorksMobileView from '@/views/cms/about-us/how-it-works/HowItWorksMobileView';

export default {
    name : 'HowItWorks',

    components : { HowItWorksMobileView, AddHowItWorks, EditHowItWorks },

    data () {
        return {
            ext_search : '',
            listUrl    : urls.cms.howItWorks.list,
            deleteUrl  : urls.cms.howItWorks.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name      : 'title',
                    sortField : 'title',
                    title     : 'Title'
                },
                {
                    name      : '__slot:is_enabled',
                    sortField : 'is_enabled',
                    title     : 'Enabled',
                    dataClass : 'center aligned'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {

        formSuccess () {
            const refs = this.$refs;
            refs.howItWorksAddModal.close();
            refs.howItWorksEditModal.close();
            refs.table.refreshTable();
            refs.howItWorkMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.howItWorksEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.howItWorksDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.howItWorksDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.howItWorkMobileView.loadData();
        }

    }
};
</script>

<style scoped>

</style>
